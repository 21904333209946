import React, { useCallback } from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useCareerPageEntry } from '@hooks/cms';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import Hero from '@c/Hero';
import MediaGrid from '@c/MediaGrid';
import SellingPoints from '@c/SellingPoints';
import Divider from '@c/Divider';
import ArticleContent from '@c/ArticleContent';
import RelatedEntries from '@c/RelatedEntries';

const CareerPage = () => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    careerPageCmsEntry = useCareerPageEntry(),
    careerPageEntry = careerPageCmsEntry.entry,
    seomatic = careerPageCmsEntry.seomatic,
    mediaGrid = careerPageEntry.mediaGrid[0],
    sellingPoints = careerPageEntry.sellingPoints,
    articleContent = careerPageEntry.articleContent,
    relatedEntriesTitle = careerPageEntry.relatedEntries[0]?.relatedTitle,
    relatedEntries = careerPageEntry.relatedEntries[0]?.relatedEntries;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <EntryContextProvider entry={careerPageEntry}>
      <Layout seomatic={seomatic}>
        {!!careerPageEntry.hero && !!careerPageEntry.hero.length && (
          <Hero {...careerPageEntry.hero[0]} />
        )}

        <ArticleContent content={articleContent} mt={19.15} />

        <VisibilitySensor id='karriere' onVisible={handleOnVisible}>
          <Box mb={19.5}>
            {(mediaGrid || (sellingPoints && !!sellingPoints.length)) && (
              <Container>
                <Grid container>
                  {mediaGrid && (
                    <Grid item xs={12} md={11}>
                      <Box pl={{ xs: 0, md: 8 }}>
                        <MediaGrid data={mediaGrid} />
                      </Box>
                    </Grid>
                  )}

                  {sellingPoints && !!sellingPoints.length && (
                    <>
                      <Grid item xs={12}>
                        <Divider mt={{ xs: 12, md: 19.5 }} />
                      </Grid>

                      <Grid item xs={12} md={11}>
                        <Box
                          mt={{ xs: 12, md: 19.4 }}
                          mb={{ xs: 19.5, md: 32 }}
                          pl={{ xs: 0, md: 8 }}
                        >
                          <Box mb={{ xs: 5, md: 12 }}>
                            <h2>Hvorfor Norse {/* TODO: fetch from backend? */}</h2>
                          </Box>
                          <SellingPoints data={sellingPoints} />
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Container>
            )}

            {relatedEntries && (
              <RelatedEntries title={relatedEntriesTitle} entries={relatedEntries} />
            )}
          </Box>
        </VisibilitySensor>
      </Layout>
    </EntryContextProvider>
  );
};

export default CareerPage;
